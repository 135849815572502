.footer{
  width: 100%;
  background: #0E0F0F;
  padding: 3rem 0.5rem 1.5rem 0.5rem;
}
.footerlogo{
  width: 150px;
}
.footerMask{
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.footerMask p{
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  text-align: center;
  text-transform: capitalize;
  color: #A1A1A1;
  margin-bottom: 0;
}
.footerCopyright{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.footerLinks{
  list-style: none;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.footerLinks li{
  position: relative;
  margin-right: 5px;
}
.footerLinks li a{
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #A1A1A1;
  text-decoration: none;
  margin: 0 0 0 10px;
}
.footerLinks li::after{
  content: '';
  width: 1px;
  height: 15px;
  background-color: #A1A1A1;
  position: absolute;
  right: -8px;
  top: 5px;
}
.footerLinks li:last-child::after{
  content: '';
  display: none;
}
.socialLinks {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 35px 0;
}
.socialLinks li{
  margin: 0px 1rem;
  width: auto;
}
.copyrightText{
  width: 100%;
}