.cmsPaesWrapper .headerComp {
  float: left;
  width: 100%;
  height: 100px;
  margin-bottom: 2rem;
  background: linear-gradient(288.21deg, #6B4EFF 6.51%, rgba(107, 78, 255, 0.79) 86.73%);
}
.cmsPaesWrapper{
  width: 100%;
  padding: 0 0 2rem 0;
  text-align: left;
}
  h1{
    font-size: 2rem;
    margin-bottom: 0.5rem;
    text-align: center;
  }
  h2{
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  .cmsPaesWrapper ul{
    list-style: none;
    margin: 0 0 1.5rem 0;
    padding: 0;
    text-align: left;
  }
  .cmsPaesWrapper li{
    width: 100%;
    margin-bottom: 0.5rem;
  }
  p{
    font-size: 16px;
    margin-bottom: 1.5rem;
  }
p a{
  color: #EE2D24;
}
p a:hover{
    color: #EE2D24;
}