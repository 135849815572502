.siteLogo{
  width: 200px;
  height: 100px;
  float: left;
}
.headerComp{
  background: linear-gradient(288.21deg, #6B4EFF 6.51%, rgba(107, 78, 255, 0.79) 86.73%);
}

.logoMask{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-top: 1.5rem;
}
.logoMask img{
  float: left;
  width: 170px;
}
.bannerInfo{
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.bannerInfo h2{
  font-weight: 600;
font-size: 45px;
line-height: 54px;
text-align: left;
color: #ffffff;
margin-bottom: 36px;
width: 85%;
}
.bannerInfo p{
  font-weight: 400;
  font-size: 22px;
  line-height: 34px;
  color: #ffffff;
  width: 85%;
  text-align: left;
  margin-bottom: 65px;
}
.bannerButton{
  text-align: left;
}
.appStoreBtn{
  width: 176px;
  height: 58px;
  background: url('../../../public/images/googleplay.svg') no-repeat;
  background-size: contain;
  border: medium none;
  margin-right: 1rem;
}
.googlePlayBtn{
  width: 177px;
  height: 53px;
  background: url('../../../public/images/appstore.svg') no-repeat;
  background-size: contain;
  border: medium none;
}
.bannerButton {
  width: 100%;
  display: flex;
}
.userSayComp{
  width: 100%;
  height: auto;
  background: #E7E7FF url('../../../public/images/clientBg.svg') no-repeat;
  background-position: 15% 100%;
  padding: 4rem 0;
  background-size: 34%;
}
#carouselExampleCaptions {
  height: 345px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.carousel-inner{
  width: 910px;
}
#carouselExampleCaptions h2{
  font-weight: 500;
  font-size: 32px;
  line-height: 39px;
  margin-bottom: 34px;
  color: #000000;
}
.carousel-item{
  text-align: center;
}
.carousel-item img{
  height: 65px;
  width: 65px;
}
.carousel-item p{
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #000000;
  opacity: 0.5;
}
.carousel-item span{
  width: 100%;
  display: inherit;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
  margin-top: 10px;

}
.getInTuch{
  padding: 3rem 0;
}
.getInTuchMask{
  width: 700px;
  margin: 0 auto;
}
.getInTuchMask .form-control{
  border: medium none;
  border-bottom: 1px solid #D9D9D9;
  border-radius: 0;
  padding: 1rem 0;
}
.form-control:focus{
  box-shadow:none;
}
.form-floating label{
  padding: 1rem 0;
}

.getInTuchMask .form-select{
  border: medium none;
  border-bottom: 1px solid #D9D9D9;
  border-radius: 0;
  padding: 1rem 0;
  margin-top: 1.2px;
}
.form-select:focus{
  box-shadow:none;
}

.sendMessagebtn{
  width: 100%;
  height: 52px;
  background: #6B4EFF;
  color: #FFFFFF;
  border-radius: 48px;
  margin-top: 51px;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
}
.sendMessagebtn:hover{
  background: #6B4EFF;
  transition: all 0.5s ease;
  opacity: 0.9;
}

.customTextarea{
  resize: none;
  height: 110px !important;
}
.serviceComp{
  padding: 3rem 0;
}
.Title{
  font-weight: 500;
  font-size: 32px;
  line-height: 39px;
  color: #000000;
  text-align: center;
  margin-bottom: 80px;
}
.serviceInfoTiles{
  display: flex;
  margin-bottom: 36px;
}
.serviceInfoImage{
  width: 120px;
}
.serviceInfoDesc{
  text-align: left;
}

.serviceInfoDesc h2{
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  color: #000000;
  margin-bottom: 12px;
  width: 400px;
}

.serviceInfoDesc p{
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
  opacity: 0.5;
  width: 400px;
  margin-bottom: 0;
}
.carousel-control-prev-icon{
  background: url('../../../public/images/nextIcon.svg')no-repeat;
  width: 50px;
  height: 50px;
  position: absolute;
  left: -3rem;
}
.carousel-control-next-icon{
  background: url('../../../public/images/previousIcon.svg')no-repeat;
  width: 50px;
  height: 50px;
  position: absolute;
  right: -3rem;
}

.titleSubtitle{
  margin-bottom: 51px;
  text-align: center;
}

.titleSubtitle h2{
  font-weight: 500;
  font-size: 32px;
  line-height: 39px;
  color: #000000;
}

.titleSubtitle p{
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #3F3F3F;
}



/* Responsive css */
@media (min-width: 1281px) and (max-width: 1366px) {}
@media (min-width: 1024px) and (max-width: 1280px) {
  .bannerInfo h2{
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 25px;
    width: 100%;
  }
  .bannerInfo p {
    font-size: 18px;
    line-height: 25px;
    width: 100%;
    margin-bottom: 40px;
  }
  .serviceInfoDesc{
    padding-left: 2rem;
  }
  .serviceInfoDesc h2{
    width: 360px;
  }
  .serviceInfoDesc p{
    width: 360px;
  }
  .carousel-control-prev-icon{
    left: -2rem;
  }
  .carousel-control-next-icon{
    right: -2rem;
  }
}
@media (min-width: 769px) and (max-width: 1023px) {
}
/*mobile vertical layout*/
@media only screen and (max-width: 768px) {
  .logoMask{
    justify-content: center;
  }
  .carousel-inner{
    width: 73%;
  }
  .getInTuchMask{
    width: 100%;
  }
  .carousel-control-prev-icon{
    position: inherit;
    left: 0;
  }
  .carousel-control-next-icon{
    position: inherit;
    right: 0;
  }
  #carouselExampleCaptions h2{
    font-size: 20px;
    line-height: 20px;
  }
  .titleSubtitle h2{
    font-size: 20px;
    line-height: 25px;
  }
  .titleSubtitle p{
    font-size: 14px;
    line-height: 24px;
  }
  .Title{
    font-size: 24px;
    line-height: 39px;
  }
  .serviceInfo{
    margin-top: 3rem;
  }
  .bannerInfo h2{
    font-size: 30px;
    line-height: 40px;
    text-align: center;
    margin-top: 3rem;
    width: 100%;
  }
  .bannerInfo p{
    width: 100%;
    text-align: center;
  }
  .bannerButton{
    align-items: center;
    justify-content: center;
  }
  .banneriPhone{
    margin-top: 2rem;
  }
  .serviceInfoTiles{
    display: inherit;
  }
  .serviceInfoImage {
    width: 100%;
    margin-bottom: 1rem;
    text-align: center;
  }
  .serviceInfoDesc h2{
    width: 100%;
    text-align: center;
  }
  .serviceInfoDesc p{
    width: 100%;
    text-align: center;
  }
  .carousel-item p {
    height: 140px;
    overflow: hidden;
  }
  .footerMask p{
    align-items: center;
    justify-content: center;
  }
  .footerLinks{
    padding: 0;
    justify-content: center !important;
  }
}
/*mobile horizontal layout*/
@media only screen and (max-width : 320px) {
}